import api from '../library/axios';

export default {
  getList(workspace_id, page, search, group_id = null) {
    return api({
      method: 'get',
      url: '/api/agents',
      params: {
        workspace_id,
        page,
        search,
        group_id,
      },
    });
  },
  asignUser({ user_id, workspace_id, agent_group_id }) {
    return api({
      method: 'post',
      url: '/api/agents',
      data: {
        user_id: Array.isArray(user_id) ? user_id : [user_id],
        workspace_id,
        agent_group_id,
      },
    });
  },
  update(data, id) {
    return api({
      method: 'patch',
      url: `/api/agents/${data.workspace_id}/${id}`,
      data,
    });
  },
  delete(agent_id) {
    return api({
      method: 'delete',
      url: `/api/agents/${agent_id}`,
    });
  },
  updateAgent(agent_id, data) {
    return api({
      method: 'patch',
      url: `/api/agents/${agent_id}`,
      data,
    });
  },
  myAgentStatus() {
    return api({
      method: 'get',
      url: '/api/agents/me/availibility',
    });
  },
  setMyAgentStatus(data) {
    return api({
      method: 'patch',
      url: '/api/agents/me/availibility',
      data,
    });
  },
  getListSummaryTicket(workspace_id, query) {
    return api({
      method: 'get',
      url: `/api/agents/${workspace_id}/summary-ticket`,
      params: query,
    });
  },
};
